













































import { Component, Vue } from "vue-property-decorator";
import { Timeline } from "vue-tweet-embed";
// @ is an alias to /src

@Component({
  components: {
    Timeline
  }
})
export default class WorksNew extends Vue {
  twitterId = "mokuhoikuen_art";
  twitterHeight = "450px";
  sourceType = "profile";
}
